import socketio from 'socket.io-client';
import customParser from 'socket.io-msgpack-parser';
import { forEach, values } from 'lodash';
import router from '@/router';
import * as api from '@/services/api';
import { createMarketBuffer } from '@/services/helpers/market-updater';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import socketEvents, { socketEventTypes } from '@/services/helpers/socket-events';
import routes from '@/../config/env';

const { socketApi } = routes;
let sio = null;
let dispatch = null;
let marketBuffer = null;
let disconnectedWhileBuffering = false;

export default {
  async connect(storeDispatch) {
    if (!dispatch) {
      dispatch = storeDispatch;
    }

    if (!sio) {
      const url = socketApi;
      const { token } = await api.isUserLoggedIn();
      const { isSuperAdmin, client } = getSuperAdminData();
      const socketOptions = {
        forceNew: true,
        transports: ['websocket'],
        allowUpgrades: false,
        parser: customParser,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,

        auth: {
          token: token ? `Bearer ${token}` : '',
        },
      };

      if (isSuperAdmin) {
        socketOptions.auth['x-admin-for'] = client;
      }

      if (!marketBuffer) {
        marketBuffer = createMarketBuffer((incomingMarkets) => {
          if (router.currentRoute.value.name === 'params-setup') {
            dispatch('batchUpdatePlayerMarkets', incomingMarkets);
          } else if (router.currentRoute.value.name === 'trading-ui-multiview') {
            dispatch('batchUpdateMultiviewMarkets', incomingMarkets);
          } else if (router.currentRoute.value.name === 'core-markets' || router.currentRoute.value.name === 'player-props') {
            dispatch('batchUpdateOddsCheckerMarkets', incomingMarkets);
          } else {
            dispatch('batchUpdateEventMarkets', incomingMarkets);
          }
        });
      }

      sio = socketio(url, socketOptions);
      sio.connect();

      sio.on('connect', () => {
        console.log('Socket connected!');
        if (disconnectedWhileBuffering) {
          marketBuffer.start();
          disconnectedWhileBuffering = false;
        }

        dispatch('toggleSocketConnection', sio);

        forEach(socketEvents.getEventTypes(), (type) => {
          switch (type) {
          case socketEventTypes.EVENT:
            dispatch('reloadEvent');
            break;
          case socketEventTypes.TRADING:
            dispatch('reloadTradingEvent');
            break;
          case socketEventTypes.EVENT_LIST:
            dispatch('loadEvents');
            break;
          case socketEventTypes.PLAYER_SETUP:
            dispatch('reloadPlayerSetupDetails');
            break;
          case socketEventTypes.RESULTING:
            dispatch('reloadManualResultingMarkets');
            break;
          case socketEventTypes.MULTIVIEW_LIST:
            dispatch('reloadMultiviewEventList');
            break;
          case socketEventTypes.MULTIVIEW:
            dispatch('reloadMultiviewDrawer');
            break;
          case socketEventTypes.BET_TICKER:
            dispatch('betTicker/reloadBetTicker', null, { root: true });
            break;
          case socketEventTypes.ODDS_CHECKER:
            dispatch('reloadOddsCheckerEvents');
            break;
          case socketEventTypes.LIABILITIES:
            dispatch('betTicker/reloadLiabilities');
            break;
          default:
          }
        });
      });

      sio.on('market', (data) => {
        if (!marketBuffer.active) return;
        marketBuffer.append(data);
      });

      sio.on('soccer', (data) => {
        dispatch('updateEvent', data);
      });

      sio.on('basketball', (data) => {
        dispatch('updateEvent', data);
        dispatch('updatePlayerSetupDetails', data);
      });

      sio.on('football', (data) => {
        dispatch('updateEvent', data);
        dispatch('updatePlayerSetupDetails', data);
      });

      sio.on('ultimate', (data) => {
        dispatch('updateEvent', data);
      });

      sio.on('hockey', (data) => {
        dispatch('updateEvent', data);
      });

      sio.on('baseball', (data) => {
        dispatch('updateEvent', data);
      });

      sio.on('feed-health', (data) => {
        dispatch('updateAllFeeds', data);
      });

      sio.on('event-info', (data) => {
        if (data.messageType === 'event-primary-feeds-updated') {
          dispatch('processEventFeedChange', data);
          return;
        }

        dispatch('processEventInfo', data);
      });

      sio.on('event-readiness', (data) => {
        dispatch('processEventReadiness', data);
      });

      sio.on('event-limit-overrides', (data) => {
        dispatch('updateEventLimits', data);
      });

      sio.on('event-flags', (data) => {
        if (data.messageType === 'event-disabled') {
          dispatch('processEventDisabled', data.eventId);
        }
      });

      sio.on('market-suspension-events', (data) => {
        const options = {
          eventId: data.eventId,
          isSuspended: data.messageType === 'event-suspended',
        };
        dispatch('setEventSuspendStatus', options);
        dispatch('setEventsEventSuspendedStatus', options);
        dispatch('setTradingUIEventSuspendStatus', options);
        dispatch('setMultiviewEventListEventSuspendStatus', options);
      });

      sio.on('player-params', (data) => {
        if (router.currentRoute.value.name === 'params-setup') {
          dispatch('updatePlayerProps', data);
        } else if (router.currentRoute.value.name === 'trading-ui') {
          dispatch('updateTradingUIPlayerProps', data);
        } else if (router.currentRoute.value.name === 'trading-ui-multiview') {
          dispatch('updateMultiviewPlayerParams', data);
        }
      });

      sio.on('game-params', (data) => {
        if (router.currentRoute.value.name === 'params-setup') {
          dispatch('socketUpdatePlayerSetupGameParams', data);
        } else if (router.currentRoute.value.name === 'trading-ui-multiview') {
          dispatch('socketUpdateMultiviewGameParams', data);
        } else if (router.currentRoute.value.name === 'trading-ui') {
          dispatch('socketUpdateTradingUIGameParams', data);
        }
      });

      sio.on('pricing-call', () => {
        if (router.currentRoute.value.name === 'params-setup') {
          dispatch('setIsPlayerSetupSimulateBySocketChangeEnabled', true);
        } else if (router.currentRoute.value.name === 'trading-ui-multiview') {
          dispatch('setIsMultiviewSimulateBySocketChangeEnabled', true);
        } else if (router.currentRoute.value.name === 'trading-ui') {
          dispatch('setIsTradingSimulateBySocketChangeEnabled', true);
        }
      });

      sio.on('bet', (data) => {
        if (data.messageType === 'bet-flag') {
          dispatch('betTicker/setIsBetFlagged', { betId: data.betId, isFlagged: data.isFlagged });
          return;
        }
        dispatch('betTicker/updateBetTickerBets', data);
      });

      sio.on('pricing-probabilities', (data) => {
        if (router.currentRoute.value.name === 'trading-ui') {
          dispatch('updateTradingUIPricingProbablities', data);
        } else if (router.currentRoute.value.name === 'params-setup') {
          dispatch('updateParamsManagerPricingProbablities', data);
        } else if (router.currentRoute.value.name === 'trading-ui-multiview') {
          dispatch('updateMultiviewDrawerPricingProbablities', data);
        }
      });

      sio.on('liability', (data) => {
        dispatch('betTicker/updateEventListLiabilities', data);
        dispatch('betTicker/updateEventLiabilities', data);
      });

      sio.on('product-health', (data) => {
        dispatch('updateProductHealth', data);
      });

      sio.on('event-markets-resulting-status', (data) => {
        dispatch('updateEventMarketsResultingStatus', data);
      });

      sio.on('disconnect', (reason) => {
        console.log('Disconnected!, Reason ---->  ', reason);
        dispatch('toggleSocketConnection', null);

        if (marketBuffer?.active) {
          marketBuffer.stop();
          disconnectedWhileBuffering = true;
        }

        sio.disconnect();
        sio = null;

        if (reason !== 'io client disconnect') { // dont reconnect on manual disconnect
          console.log('Reconnecting...');
          dispatch('connectWebSocket');
        }
      });
    }

    return sio;
  },

  subscribeToEvents({ id, source, type = socketEventTypes.EVENT }) {
    if (!sio) return;

    socketEvents.register(
      type,
      {
        eventIds: [id],
        eventSources: [source],
      },
      () => {
        if (source) {
          sio.emit('subscribe', {
            eventIds: [id],
            subscribeFor: 'EVENTS_BY_SOURCE',
            sources: [source],
          });
        }

        sio.emit('subscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds: [id],
        });
        sio.emit('subscribe', {
          subscribeFor: 'EVENT_LIMIT_OVERRIDES',
          eventIds: [id],
        });
        sio.emit('subscribe', {
          eventIds: [id],
          subscribeFor: 'MARKETS',
        });
      },
    );

    marketBuffer.start();
  },
  unsubscribeFromEvents({ id, source, type = socketEventTypes.EVENT }) {
    if (!sio) return;

    socketEvents.unregister(
      type,
      {
        eventIds: [id],
        eventSources: [source],
      },
      () => {
        if (source) {
          sio.emit('unsubscribe', {
            eventIds: [id],
            subscribeFor: 'EVENTS_BY_SOURCE',
            sources: [source],
          });
        }
        sio.emit('unsubscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds: [id],
        });
        sio.emit('unsubscribe', {
          subscribeFor: 'EVENT_LIMIT_OVERRIDES',
          eventIds: [id],
        });
        sio.emit('unsubscribe', {
          eventIds: [id],
          subscribeFor: 'MARKETS',
        });
      },
    );

    marketBuffer.stop();
  },

  subscribeEventList(ids) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.EVENT_LIST,
      {
        subscribeFor: 'EVENTS_INFO',
        eventIds: values(ids)?.[0] || [],
      },
      () => {
        sio.emit('subscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds: values(ids)?.[0] || [],
        });
      },
    );
  },
  unsubscribeEventList(ids) {
    if (!sio) return;

    socketEvents.unregister(
      socketEventTypes.EVENT_LIST,
      {
        subscribeFor: 'EVENTS_INFO',
        eventIds: values(ids)?.[0] || [],
      },
      () => {
        sio.emit('unsubscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds: values(ids)?.[0] || [],
        });
      },
    );
  },

  subscribeToFeedHealth() {
    if (!sio) return;

    sio.emit('subscribe', {
      subscribeFor: 'FEED_HEALTH',
    });
  },

  subscribeToManualResultingMarkets(eventId) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.RESULTING,
      {
        subscribeFor: 'MARKETS',
        eventIds: [eventId],
      },
      () => {
        sio.emit('subscribe', {
          subscribeFor: 'MARKETS',
          eventIds: [eventId],
        });
      },
    );

    marketBuffer.start();
  },
  unsubscribeToManualResultingMarkets(eventId) {
    if (!sio) return;

    socketEvents.unregister(
      socketEventTypes.RESULTING,
      {
        subscribeFor: 'MARKETS',
        eventIds: [eventId],
      },
      () => {
        sio.emit('unsubscribe', {
          subscribeFor: 'MARKETS',
          eventIds: [eventId],
        });
      },
    );

    marketBuffer.stop();
  },
  subscribeToTradingUiMarkets(eventId) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.TRADING,
      {
        subscribeFor: 'MARKETS',
        eventIds: [eventId],
      },
      () => {
        sio.emit('subscribe', {
          subscribeFor: 'MARKETS',
          eventIds: [eventId],
        });
      },
    );

    marketBuffer.start();
  },
  unsubscribeToTradingUiMarkets(eventId) {
    if (!sio) return;

    socketEvents.unregister(
      socketEventTypes.TRADING,
      {
        subscribeFor: 'MARKETS',
        eventIds: [eventId],
      },
      () => {
        sio.emit('unsubscribe', {
          subscribeFor: 'MARKETS',
          eventIds: [eventId],
        });
      },
    );

    marketBuffer.stop();
  },
  subscribeToPlayerProps(eventId) {
    if (!sio) return;

    sio.emit('subscribe', {
      eventIds: [eventId],
      subscribeFor: 'PLAYER_PARAMS',
    });
  },
  unsubscribeFromPlayerProps(eventId) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      eventIds: [eventId],
      subscribeFor: 'PLAYER_PARAMS',
    });
  },
  subscribeToPricingOnDemand(eventId) {
    if (!sio) return;

    sio.emit('subscribe', {
      eventIds: [eventId],
      subscribeFor: 'PRICING_CALL',
    });
  },
  unsubscribeFromPricingOnDemand(eventId) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      eventIds: [eventId],
      subscribeFor: 'PRICING_CALL',
    });
  },
  subscribeMultiviewEventList(groupedEventsBySource) {
    if (!sio) return;

    forEach(groupedEventsBySource, (ids, source) => {
      socketEvents.register(
        socketEventTypes.MULTIVIEW_LIST,
        {
          eventIds: ids,
          sources: [source],
        },
        () => {
          sio.emit('subscribe', {
            subscribeFor: 'EVENTS_INFO',
            eventIds: ids,
          });
          sio.emit('subscribe', {
            subscribeFor: 'EVENTS_BY_SOURCE',
            eventIds: ids,
            sources: [source],
          });
          sio.emit('subscribe', {
            subscribeFor: 'EVENT_LIMIT_OVERRIDES',
            eventIds: ids,
            sources: [source],
          });
          sio.emit('subscribe', {
            eventIds: ids,
            subscribeFor: 'MARKETS_BY_CODE',
            marketCodes: ['RESULT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER'],
          });
          sio.emit('subscribe', {
            subscribeFor: 'GAME_PARAMS',
            eventIds: ids,
          });
        },
      );
    });

    marketBuffer.start();
  },
  unsubscribeMultiviewEventList(groupedEventsBySource) {
    if (!sio) return;

    forEach(groupedEventsBySource, (ids, source) => {
      socketEvents.unregister(
        socketEventTypes.MULTIVIEW_LIST,
        {
          eventIds: ids,
          sources: [source],
        },
        () => {
          sio.emit('unsubscribe', {
            subscribeFor: 'EVENTS_INFO',
            eventIds: ids,
          });
          sio.emit('unsubscribe', {
            subscribeFor: 'EVENTS_BY_SOURCE',
            eventIds: ids,
            sources: [source],
          });
          sio.emit('unsubscribe', {
            subscribeFor: 'EVENT_LIMIT_OVERRIDES',
            eventIds: ids,
          });
          sio.emit('unsubscribe', {
            subscribeFor: 'MARKETS_BY_CODE',
            eventIds: ids,
            marketCodes: ['RESULT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER'],
          });
          sio.emit('unsubscribe', {
            subscribeFor: 'GAME_PARAMS',
            eventIds: ids,
          });
        },
      );
    });

    marketBuffer.stop();
  },
  subscribeMultiviewEvent(eventId) {
    if (!sio || !eventId) return;

    socketEvents.register(
      socketEventTypes.MULTIVIEW,
      {
        eventIds: [eventId],
      },
      () => {
        sio.emit('subscribe', {
          eventIds: [eventId],
          subscribeFor: 'MARKETS',
        });
      },
    );
  },
  unsubscribeMultiviewEvent(eventId) {
    if (!sio || !eventId) return;

    socketEvents.unregister(
      socketEventTypes.MULTIVIEW,
      {
        eventIds: [eventId],
      },
      () => {
        sio.emit('unsubscribe', {
          eventIds: [eventId],
          subscribeFor: 'MARKETS',
        });
      },
    );
  },
  subscribeToGameParams(eventId) {
    if (!sio) return;

    sio.emit('subscribe', {
      eventIds: [eventId],
      subscribeFor: 'GAME_PARAMS',
    });
  },
  unsubscribeFromGameParams(eventId) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      eventIds: [eventId],
      subscribeFor: 'GAME_PARAMS',
    });
  },
  // odds checker
  subscribeOddsCheckerEvents(eventIds, marketCodes) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.ODDS_CHECKER,
      {
        eventIds,
        marketCodes,
      },
      () => {
        sio.emit('subscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds,
        });
        sio.emit('subscribe', {
          eventIds,
          subscribeFor: 'MARKETS_BY_CODE',
          marketCodes,
        });
        sio.emit('subscribe', {
          eventIds,
          subscribeFor: 'EXT_MARKETS_BY_SOURCE_AND_CODE',
          sources: ['BET_365'],
          marketCodes,
        });
      },
    );

    marketBuffer.start();
  },
  unsubscribeOddsCheckerEvents(eventIds, marketCodes) {
    if (!sio) return;

    socketEvents.unregister(
      socketEventTypes.ODDS_CHECKER,
      {
        eventIds,
        marketCodes,
      },
      () => {
        sio.emit('unsubscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds,
        });
        sio.emit('unsubscribe', {
          eventIds,
          subscribeFor: 'MARKETS_BY_CODE',
          marketCodes,
        });
        sio.emit('unsubscribe', {
          eventIds,
          subscribeFor: 'EXT_MARKETS_BY_SOURCE_AND_CODE',
          sources: ['BET_365'],
          marketCodes,
        });
      },
    );

    marketBuffer.stop();
  },
  subscribeToBetTicker(betTickerId) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.BET_TICKER,
      { betTickerId },
      () => {
        const { isSuperAdminSelected } = getSuperAdminData();
        const betTickerType = betTickerId ? 'BET_TICKER' : 'DEFAULT_BET_TICKER';
        const superAdminType = betTickerId ? 'SUPER_ADMIN_BET_TICKER_BY_BET_TICKER_ID' : 'SUPER_ADMIN_BET_TICKER';
        const subscribeFor = isSuperAdminSelected ? superAdminType : betTickerType;

        if (betTickerId) {
          sio.emit('subscribe', {
            subscribeFor,
            betTickerId,
          });
        } else {
          sio.emit('subscribe', {
            subscribeFor,
          });
        }
      },
    );
  },
  unsubscribeFromBetTicker(betTickerId) {
    if (!sio) return;

    socketEvents.unregister(
      socketEventTypes.BET_TICKER,
      { betTickerId },
      () => {
        const { isSuperAdminSelected } = getSuperAdminData();
        const betTickerType = betTickerId ? 'BET_TICKER' : 'DEFAULT_BET_TICKER';
        const superAdminType = betTickerId ? 'SUPER_ADMIN_BET_TICKER_BY_BET_TICKER_ID' : 'SUPER_ADMIN_BET_TICKER';
        const subscribeFor = isSuperAdminSelected ? superAdminType : betTickerType;

        if (betTickerId) {
          sio.emit('unsubscribe', {
            subscribeFor,
            betTickerId,
          });
        } else {
          sio.emit('unsubscribe', {
            subscribeFor,
          });
        }
      },
    );
  },
  subscribeToBetInfo() {
    if (!sio) return;

    sio.emit('subscribe', {
      subscribeFor: 'BET_INFO',
    });
  },
  unsubscribeFromBetInfo() {
    if (!sio) return;

    sio.emit('unsubscribe', {
      subscribeFor: 'BET_INFO',
    });
  },
  subscribeToPricingProbabilities(eventId) {
    if (!sio) return;

    sio.emit('subscribe', {
      eventIds: [eventId],
      subscribeFor: 'PRICING_PROBABILITIES',
    });
  },
  unsubscribeFromPricingProbabilities(eventId) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      eventIds: [eventId],
      subscribeFor: 'PRICING_PROBABILITIES',
    });
  },
  disconnect() {
    if (!sio) return;

    marketBuffer.stop();
    marketBuffer = null;

    socketEvents.clearAll();

    sio.disconnect();
    sio = null;
  },
  subscribeToEventListLiabilities(eventIds) {
    if (!sio) return;
    socketEvents.register(socketEventTypes.LIABILITIES);
    sio.emit('subscribe', {
      subscribeFor: 'LIABILITIES',
      eventIds,
    });
    sio.emit('subscribe', {
      subscribeFor: 'EVENTS_INFO',
      eventIds,
    });
  },
  unsubscribeFromEventListLiabilities(eventIds) {
    if (!sio) return;
    sio.emit('unsubscribe', {
      subscribeFor: 'LIABILITIES',
      eventIds,
    });
    sio.emit('unsubscribe', {
      subscribeFor: 'EVENTS_INFO',
      eventIds,
    });
    socketEvents.unregister(socketEventTypes.LIABILITIES);
  },
  subscribeToEventLiabilities(eventId) {
    if (!sio) return;
    sio.emit('subscribe', {
      subscribeFor: 'LIABILITIES',
      eventIds: [eventId],
    });
    sio.emit('subscribe', {
      subscribeFor: 'EVENTS_INFO',
      eventIds: [eventId],
    });
  },
  unsubscribeFromEventLiabilities(eventId) {
    if (!sio) return;
    sio.emit('unsubscribe', {
      subscribeFor: 'LIABILITIES',
      eventIds: [eventId],
    });
    sio.emit('unsubscribe', {
      subscribeFor: 'EVENTS_INFO',
      eventIds: [eventId],
    });
  },
  subscribeToProductHealth(operatorIds) {
    if (!sio) return;
    sio.emit('subscribe', {
      subscribeFor: 'PRODUCT_HEALTH',
      operatorIds,
    });
  },
  subscribeToEventResultingStatus(eventIds) {
    if (!sio) return;

    sio.emit('subscribe', {
      subscribeFor: 'EVENT_MARKETS_RESULTING_STATUS',
      eventIds,
    });
  },
  unsubscribeFromEventResultingStatus(eventIds) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      subscribeFor: 'EVENT_MARKETS_RESULTING_STATUS',
      eventIds,
    });
  },
};
